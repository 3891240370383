// i18next-extract-mark-ns-start ecommerce-marketplaces

import {SEO} from 'components/SEO';
import {graphql} from 'gatsby';
import {Trans, useI18next} from 'gatsby-plugin-react-i18next';
import React from 'react';

const Ecomm: React.FC<{}> = () => {
  const {t} = useI18next();
  const title = t('Ecommerce Marketplaces');
  return (
    <div className="py-24 md:py-32">
      <SEO title={title} />
      <div className="container px-6 py-7 md:py-14 relative z-10 min-h-screen flex justify-center">
        <article className="prose prose-sm sm:prose xl:prose-lg max-w-full">
          <h1>{title}</h1>
          <Trans parent="p">
            microapps is an e-commerce intelligence firm helping businesses understand and grow in
            the e-commerce business via expertise signaled by data.
          </Trans>
          <Trans parent="p">
            We collect data on European e-commerce marketplaces like Amazon, C-discount, El Corte
            Inglés, Aliexpress, Zalando, PcComponentes, ManoMano, MediaMarkt & others. Exclusive software parses crude data from
            web-scraping and APIs to secure significant pieces of data on brands, products,
            categories, and sellers.
          </Trans>
          <Trans parent="p">
            Clients include marketplaces, financial technology, software, and retail services
            companies. We work with companies behind-the-scenes of e-commerce, most often by
            providing data APIs and advanced operational tools like catalogs, PO's, invoicing, &
            logistics management.
          </Trans>
          <Trans parent="p">
            We either sell on behalf of retail companies or create and sell our proprietary MNDB's
            (marketplace native digital brands). When working with existing brands or retail
            distributors, we use our own bidding system making sure our PO's get the best supplier
            in real-time.
          </Trans>
          <Trans parent="p">
            Willing to work with us? We only work with reputable brands that have a strong market
            presence & with a minimum gross turnover of 5 Million € / year within any channel.
          </Trans>
          <Trans parent="p">
            Already a partner? Log-in to our <a href="https://pim.microapps.com/">PIM</a>.
          </Trans>
        </article>
      </div>
    </div>
  );
};

export default Ecomm;

export const query = graphql`
  query($language: String!) {
    locales: allLocale(
      filter: {ns: {in: ["common", "ecommerce-marketplaces"]}, language: {eq: $language}}
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
